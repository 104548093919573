@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

.w-30 {
  width: 30%;
}

.h-25 {
  height: 25%;
}

.body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", "sans-serif", monospace;
  background-color: #f7f8f8;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.controls {
  position: absolute;
  right: 0;
  top: 30px;
}

.main-nav {
  background-color: #f7f8f8be;
  backdrop-filter: blur(10px);
  position: sticky !important;
  top: 0px;
  z-index: 1;
  border-bottom: 1px solid black;
  height: 100px;
}

.navItems a {
  text-decoration: none;
  color: black;
}

.navItems a:hover {
  color: black;
}

.navbarSocialLinks a {
  text-decoration: none;
  color: black;
  transition: all 0.2s ease-in-out;
}

.navbarSocialLinks a:hover {
  transform: scale(1.2);
  color: black;
}

.newLogo {
  width: 100px;
  height: 100px;
}

.navbarSocialLinks {
  display: flex;
  justify-content: end;
  gap: 2rem;
}

.social-media-icons {
  width: 25px;
  height: 25px;
}

.epicode-student {
  font-size: 1.5rem;
  font-weight: 300;
}

.developer {
  font-size: 2rem;
  font-weight: 400;
}

.my-pic {
  width: 350px;
  height: 520px;
  object-fit: cover;
  border-radius: 300px;
  box-shadow: 4px 6px 8px grey;
}

.my-pic-blur {
}

.hero-titles {
  color: grey;
  font-size: 0.7rem;
  font-weight: 400;
}

.hero-text {
  font-size: 0.8rem;
  font-weight: 500;
}

.hero-numbers {
  font-size: 2rem;
}

.contact-links {
  color: black;
}

.technologies {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.skill-card {
  width: 7rem;
  height: 10rem;
  background-color: #e0e0e0e0;
  border-radius: 56px;
}

.skill-icons {
  margin-top: 1rem;
  width: 3rem;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
}

.skill-card:hover > a > .skill-icons {
  filter: grayscale(0%);
  cursor: pointer;
  transform: scale(1.1);
}

.skill-icons:hover {
  filter: grayscale(0%);
  cursor: pointer;
  transform: scale(1.1);
}

.skill-percentage {
  font-size: 1.5rem;
}

.skill-name {
  font-size: 0.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.education {
  border-bottom: 1px solid black;
}

.year {
  font-size: 0.6rem;
  color: rgb(191, 190, 190);
  margin-bottom: 2rem;
}

.graduated {
  font-size: 1.1rem;
  line-height: 1.2;
}

.location {
  font-size: 0.7rem;
  color: grey;
}

.projects {
  border-bottom: 1px solid black;
}

.projectTitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.projectImg {
  width: 100%;
  object-fit: cover;
}

.projectLinks {
  margin-block: 1rem;
}

.projectLinks a {
  text-decoration: none;
}

.projectRepo {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border: 1px solid black;
  border-radius: 10px;
  color: black;
  transition: all 0.2s ease-in-out;
}

.projectRepo:hover {
  background-color: black;
  color: white;
  transform: scale(1.1);
}

.projectDemo {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border: 1px solid black;
  border-radius: 10px;
  background-color: black;
  color: white;
  transition: all 0.2s ease-in-out;
}

.projectDemo:hover {
  background-color: white;
  color: black;
  transform: scale(1.1);
}

.proj:hover .project-details {
  visibility: visible;
  font-size: 2rem;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.proj {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-details {
  background-color: #f7f8f851;
  backdrop-filter: blur(10px);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}

.testimonials {
  border-bottom: 1px solid black;
}

.testimonialText {
  text-align: center;
  font-size: 1.2rem;
}

.text-blue {
  font-weight: bold;
  margin-left: 1rem;
}

.callAndy {
  width: 200px;
  height: 200px;
  margin-block: 3rem;
  border: 1px solid;
  border-style: dashed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.describe {
  font-size: 0.5rem;
}

.call {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 2rem;
}

.pew {
  width: 30%;
}

.footerSocialLinks a {
  text-decoration: none;
  color: black;
  transition: all 0.2s ease-in-out;
}

.footerSocialLinks a:hover {
  transform: scale(1.2);
  color: black;
}

.project-image {
  width: 50%;
}

.project-description {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid black;
}

.project-technologies {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.technology-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-block: 1rem;
}
.technology-title {
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

.project-features {
  border-bottom: 1px solid black;
}

.call-link {
  text-decoration: none;
  color: black;
}

.call-link:hover {
  text-decoration: none;
  color: black !important;
}

@media screen and (max-width: 992px) {
  .logo {
    display: none;
  }
  .navItems {
    background-color: #f7f8f8df;
    backdrop-filter: blur(10px);
    flex-direction: column;
    border-bottom: 1px solid black;
    flex-grow: 1;
    text-align: end;
    padding-bottom: 1rem;
  }
  .navbarSocialLinks {
    display: none;
  }
  .brand {
    display: flex;
    cursor: pointer;
    transform: translateX(-20%);
  }
  .project-description {
    flex-direction: column;
  }
}

@media screen and (min-width: 993px) {
  .brand {
    display: none;
  }
}

.footer-name {
  font-size: 14px;
}
